import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import Images from "./index-sections/Images.js";
import BasicElements from "./index-sections/BasicElements.js";
import Navbars from "./index-sections/Navbars.js";
import Tabs from "./index-sections/Tabs.js";
import Pagination from "./index-sections/Pagination.js";
import Notifications from "./index-sections/Notifications.js";
import Typography from "./index-sections/Typography.js";
import Javascript from "./index-sections/Javascript.js";
import Carousel from "./index-sections/Carousel.js";
import NucleoIcons from "./index-sections/NucleoIcons.js";
import CompleteExamples from "./index-sections/CompleteExamples.js";
import SignUp from "./index-sections/SignUp.js";
import Examples from "./index-sections/Examples.js";
import Download from "./index-sections/Download.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Images />
          <div className="section text-center" id="solutions">
            <div className="container">
              <div className="row">
                <div className="col-md-8 ml-auto mr-auto">
                  <h2>Web solutions with a personal touch.</h2>
                  <h5 className="description">
                    As our client, your business needs come first. In services rendered
                    and client support, you can expect the very best.
                  </h5>
                  <br />
                </div>
              </div>
              <br /><br />
              <div className="row">
                <div className="col-md-3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="material-icons ico-lrg">code</i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Web Development</h4>
                      <p className="description">
                        From design and coding to hosting your website, we've got your
                        back.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="material-icons ico-lrg">bar_chart</i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Digital Analytics</h4>
                      <p>
                        Actionable data and streamlined reporting let you make informed
                        business decisions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="material-icons ico-lrg">people_outline</i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Social Media</h4>
                      <p>
                        Social media is a pivot component of web marketing. Make your
                        presence known,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="info">
                    <div className="icon icon-danger">
                      <i className="material-icons ico-lrg">shopping_cart</i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Storefronts</h4>
                      <p>
                        Whether you're looking for a new storefront or to refine what you
                        have, we can help.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <BasicElements />
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />
          <Typography />
          <Javascript />
          <Carousel />
          <NucleoIcons />
          <CompleteExamples />
          <SignUp />
          <Examples />
          <Download /> */}
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
